@import '../../assets/styles/custom/variables.scss';
@import '../../assets/styles/custom/mixins.scss';

.sidebar {
  @include fullsize;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;

  .loader {
    flex: 1;
    padding-top: 30vh;
    align-self: center;
  }

  .headerLoading {
    display: flex;
    flex-direction: row;
    justify-content: right;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    .headerText {
      font-weight: 500;
      color: $bgDark;
      .headerTextSpan {
        font-weight: 200;
        color: $greyDark2;
      }
    }
  }

  .pagesList {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    align-items: stretch;

    .pageItem {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem 1rem 1rem;
      background-color: transparent;
      border: none;
      border-radius: 8px;

      &:hover, &:focus {
        background-color: $bg2;
        :global {
          .floating-menu {
            visibility: visible;
          }
        }
      }

      :global {
        .floating-menu  {
          visibility: hidden;
          position: absolute;
          top: 0;
          right: 0;
          margin: 5px 5px;
          display: flex;
          flex-direction: row;
  
          &__button {
            background-color: $bgDark;
            padding: 3px 7px;
            border: none;
            border-radius: 3px;
            margin-left: 5px;
            color: $white;
            opacity: 0.5;

            &:hover, &:focus {
              opacity: 1;
            }
          }
        }
      }
      .pageNumber{
        flex-direction: center;
        align-items: center;
        color: $bgDark;
        font-size: 16pt;
        font-weight: 400;
      }

      :global {
        .pageThumbnail {
          position: relative;
          background-color: white;
          border: 2px solid $border2;
          border-radius: 6px;
          line-height: 0;
        }
      }
    }

    :global {
      .active {
        .pageThumbnail {
          border-color: $primary;
        }
      }
    }
  }

  .backButton {
    background-color: transparent;
    border: none;
  }

  .addPageButton {
    width: 100%;
    padding: 8px;
    background-color: #F6F7FA ;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover, &:focus {
      background-color: $primaryLighter;
    }

    .addPageIcon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    .addPageText {
      font-weight: 500;
      color: $bgDark;
    }
  }
}
