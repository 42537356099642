.propertySearchSidebar {
  width: 100%;
  height: 100%;
  background-color: white;
  // overflow: scroll;
  padding: 1rem 2rem;

  .fullLengthButton {
    width: 100%;
    min-height: 40px;
    margin-bottom: 10px;
  }

  .buttonText {
    font-size: 13px;
  }
}