@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.lockMenu {

    button {
        padding: 5px 5px !important;
    }
    
    .btn-primary:disabled {
        background-color: unset;
        border: unset;
    }

    .lockMenuItem {
        padding: 0.5em 1rem;
        display: flex;
        align-items: center;

        > span {
            flex-grow: 1;
            padding-right: 3em;
        }
    }

}