@import '../../../assets/styles/custom/variables.scss';

.tabbar-tabs {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  text-decoration: none;
  list-style: none;

  .nav-item {
    margin-right: 1.5em;
    .nav-btn {
      color: $primaryLight;
      background: transparent;
      border: none;
      font-size: 10pt;
      font-weight: 500;
      letter-spacing: 0.7px;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
  .active {
    padding-bottom: 3px;
    border-bottom: 3px solid $primary;
    .nav-btn {
      color: $white;
    }
  }
}