$white: #ffffff;
$black: #000000;
$grey: #d4d4d5;
$greyLight: #dadadc;
$greyLight2: #b4b4bc;
$greyLight3: #7f7f8c;
$greyLight4: #E1E5E8;
$greyDark: #a4a4a7;
$greyDark2: #858589;
$greyDark3: #6a6a77;
$mainDark: #121219;
$mainDark2: #344651;

$primary: #37aae1;
$primaryLight: #acc9d8;
$primaryLighter: #ebf1f5;

$bg: #f4f4f4;
$bg2: #f6f5fa;
$bg3: #F4F4F5;
$bgDark: #223646;
$bgDarker: #17252f;
$bgLighter: #3D5160 ;
$bgDark-highlight: #324554;

$border2: #a5adad;
$border: #c2c2c4;

$neutralGray-900: #121219;
$error-500: #d80936;
$warning-500: #ea8915;
$success-500: #4bb543;

$mobileBreakpoint: 676px;
$tabBreakpoint: 1080px;

$breadcrumb-divider: '›';

// :root {
//   --toastify-toast-width: 400px;
//   --toastify-color-light: #463da3;
//   --toastify-text-color-light: #fff;
// }

$theme-colors: (
    "primary": $primary,
    "warning": $warning-500,
    "success": $success-500,
    "danger": $error-500
);