@import '../../../../assets/styles/custom/variables.scss';
@import '../../../../assets/styles/custom/mixins.scss';


.applytemplate  {
  @include fullsize;

  &__header {
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
  
    flex-direction: row;
    align-items: center;
    line-height: 0;
    color: $primaryLight;

    &__button {
      margin-right: 0.5em;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-weight: 400;
      font-size: 11pt;
    }
  }

  &__apply {
    width: 100%;
    border: none;
    background: $bgDarker;
    border-radius: 4px;
    padding: 9px;
    margin-bottom: 0.8rem;

    &__title {
      color: $white;
      font-weight: 400;
      font-size: 14px
    }
  }

  &__pageitem {
    position: relative;
    border: none;
    background-color: $white;
    width: 100%;
    min-height: 280px;
    // padding-bottom: 100%;
    object-fit: contain;
    margin-bottom: 1rem;
    
    .pageTemplateHoverOverlay {
      position: absolute;
      visibility: hidden;
      background-color: $bgDark;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }

    .pageTemplateHoverContainer {
      position: absolute;
      visibility: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include flex-center;

      &--add-icon {
        width: 70px;
        height: 70px;
      }
    }

    &:hover {
      .pageTemplateHoverOverlay {
        visibility: visible;
      }
      .pageTemplateHoverContainer {
        visibility: visible;
      }
    }
  }
}