@import '../../assets/styles/custom/variables.scss';
@import '../../assets/styles/custom/mixins.scss';

.header {
  padding: 0.25rem 1rem 0.25rem 1rem;
  min-height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $white;

  &__buttoncol {
    @include flex-center;
    flex-direction: row;
  }

  &__saving-indicator {
    font-size: 12pt;
    font-weight: 400;
    color: $bgDark;
    opacity: 0.5;
    margin-left: 1rem;
  }

  .btn-primary:disabled {
    background-color: unset;
    border: unset;
  }

  // ------------- unused--------------------
  .header-file {
    padding: 10px 15px;
    margin: 0 0.25rem;
    background-color: transparent;
    border: none;
    border-radius: 0.25em;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $bg;
    }

    &--icon{
      height: 23px;
      margin-right: 5px;
    }

    &--text{
      font-size: 16px;
      font-weight: 600;
      color: $bgDark;
    }
  }
  // ----------------end-------------------
}
