@import '../../../../assets/styles/custom/variables.scss';
@import '../../../../assets/styles/custom/mixins.scss';

.stock-content {
  @include fullsize;
  .searchbar {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    &__input {
      display: flex;
      flex: 1;
      padding-left: 5px;
      color: white;
      background-color: $bgDarker;
      border: 1px solid $bgDarker;
      border-right: none;

      &:focus {
        border: 1px solid $primaryLight;
        border-right: none;
      }
    }
    &__button {
      margin: 0;
      padding: 5px;
      color: $primaryLight;
      background-color: $bgDarker;
      border: 1px solid $bgDarker;
      border-left: none;
      &:focus,
      &:hover {
        color: $primary;
      }
    }
    .searchbar__input:focus + .searchbar__button {
      color: $white;
      border: 1px solid $primaryLight;
      border-left: none;

      &:focus,
      &:hover {
        color: $primary;
      }
    }
  }

  .imagecolumns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    padding-bottom: 0.5rem;
    &__column {
      display: flex;
      flex-direction: column;
      flex: 1;

      .img-item {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 3px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .loading {
    @include fullsize;
    @include flex-center;
    .bars {
      height: 15%;
      width: 15%;
    }
  }
}
