@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.text-content {
  @include fullsize;

  .sub-heading {
    color: $primaryLight;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  .text-items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .text-btn {
      width: 100%;
      border-radius: 5px;
      padding: 0px 20px;
      height: 3rem;
      margin-bottom: 0.4rem;

      display: flex;
      align-items: center;
      justify-content: left;
      background: #17252f;
      border: none;
      color: $primaryLight;

      &:hover,
      &:active,
      &:focus {
        background: $bgDark-highlight;
      }

      h3,
      h4,
      h5,
      p {
        color: $primaryLight;
        font-weight: normal;
        letter-spacing: 0px;
        margin-bottom: 0;
      }

      .heading {
        font-size: 22pt;
      }
      .subheading {
        font-size: 16pt;
      }
      .bodytext {
        font-size: 11pt;
        margin-bottom: 0;
      }
      .normaltext {
        font-size: 11pt;
      }
    }
  }
}
