@import '../../../assets/styles/custom/variables.scss';

.custom-dialog {
    .modal-dialog {
        .modal-content {
            padding: 14px;
    
    
            .modal-header {
                border-bottom: unset;
                padding-top: 0;
                padding-bottom: 0;  
            }
    
            .modal-footer {
                border-top: unset;
                padding: 0;
            }
    
            .btn-primary {
                color: $white;
            }
        }
    }
}