@import '../../../assets/styles/custom/mixins.scss';

.signin {
  @include flex-center;
  @include fullsize;

  background-color: #EEF0F2;
  
  .opacity0 {
    opacity: 0;
  }

  img {
    width: 300px;

    transition: opacity 1s linear;

  
  }

}
