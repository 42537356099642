@import '../../assets/styles/custom/variables.scss';
@import '../../assets/styles/custom/mixins.scss';

.loading-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(255, 255, 255, 0.6);
    z-index: 999;

    &__inner {
        left: 50%;
        top: 50%;
        position: absolute;
    }
}