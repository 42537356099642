@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.layers-content {
  @include fullsize;

  height: auto;

  *:nth-child(2) {
    overflow: initial !important;    
  }

  .bp4-editable-text::before {
    content: unset !important;
  }

  & > div > div > div > div {

    background-color: #0E2535; 

    input {

      background-color: rgba($color: #000000, $alpha: 0.2);

      width: 130px !important;

      &::placeholder {
        color: white;
      }
    }

    > div > div {
      color: #ACC9D8;
      opacity: 1 !important;

     

      &:hover {
        color: white;

        svg {
          color: white;
        }
      }

      svg {
        color: #ACC9D8;
      }
    }

    
    &[style*="background-color"] {
      background-color: #00ABE6 !important;

      > div > div {
        color: white !important;
      }
      
    }
  }

}
