@import '../../assets/styles/custom/variables.scss';

.sidebar {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;


  &__menu-items {
    height: 100%;
    width: 57px;
    padding: 10px;
    background: $bgDarker;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      height: 50px;
    }

    .main-items {
      display: flex;
      flex-direction: column;
    }

    .misc-items {
      direction: flex;
      flex-direction: column;
    }

    .nav-btn {
      margin: 0 0;
      background-color: transparent;
      padding: 5px;
      border: none;
      color: $primaryLight;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
    .active {
      color: $primary;
    }
  }

  &__menu-content {
    display: block;
    position: relative;
    z-index: 11;
    width: 350px;
    height: 100%;
    padding: 15px 20px 15px 15px;
    background: $bgDark;
    overflow: auto;
  }

  .sidebarHidden {
    display: none;
  }

  .sidebarShown {
    display: block;
  }

  &__menutoggle {
    // TODO: Temporary hide due to scrollbar conflict
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    width: 15px;
    height: 43px;
    z-index: 11;
    border: none;
    border-radius: 4px 0px 0px 4px;
    background-color: $bgLighter;
    align-items: center;
    justify-content: center;
  }

}