@import '../../../../assets/styles/custom/variables.scss';
@import '../../../../assets/styles/custom/mixins.scss';

.linesandshapes-content {
  @include fullsize;

  .loading {
    @include fullsize;
    @include flex-center;
    .bars {
      height: 15%;
      width: 15%;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-items: center;

    .item {
      flex: 32%;
      margin-bottom: 10px;
    }

    .shapes-btn {
      @include flex-center;
      background: #17252f;
      width: 70px;
      height: 70px;
      border: none;
      color: $primaryLight;

      &:hover,
      &:active,
      &:focus {
        background: $bgDark-highlight;
      }

      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  h5 {
    font-size: 14pt;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
}