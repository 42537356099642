.custom-menu__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12%;
  margin-right: 1rem;
  color: #8DA3B0;
}

.szh-menu-container { 
  position: absolute;
}

.szh-menu {
  font-family: inherit;
  color: #344651;
  padding-top: 1rem;
  padding-bottom: 1rem;
  scroll-padding-bottom: 0.5rem;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.custom-menu__subtitle2 {
  color: #8DA3B0;
  margin-bottom: 5px;
  margin-left: 1rem;
  font-size: 14px;
}