@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fullsize {
  height: 100%;
  width: 100%;
}

@mixin button-reset {
  border: none;
  background-color: transparent;
  line-height: 0;
}