@font-face {
  font-family: 'Larsseit';
  src: url("../../fonts/Larsseit.ttf") format('ttf'),
  url("../../fonts/Larsseit.woff") format('woff'),
  url("../../fonts/Larsseit.eot") format('eot');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url("../../fonts/Larsseit-Light.ttf") format('ttf'),
  url("../../fonts/Larsseit-Light.woff") format('woff'),
  url("../../fonts/Larsseit-Light.eot") format('eot');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url("../../fonts/Larsseit-Medium.ttf") format('ttf'),
  url("../../fonts/Larsseit-Medium.woff") format('woff'),
  url("../../fonts/Larsseit-Medium.eot") format('eot');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  src: url("../../fonts/Larsseit-Bold.ttf") format('ttf'),
  url("../../fonts/Larsseit-Bold.woff") format('woff'),
  url("../../fonts/Larsseit-Bold.eot") format('eot');
  font-weight: 700;
  font-style: normal;
}
