@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.templates-content {
  @include fullsize;

  .loading {
    @include fullsize;
    @include flex-center;
    .bars {
      height: 15%;
      width: 15%;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    // padding-bottom: 0.5rem;

    .item-btn {
      overflow: hidden;
      position: relative;
      width: 100%;
      padding: 5px;
      margin-bottom: 10px;
      border: none;
      background: transparent;
      color: $primaryLight;
      text-align: center;

      display: flex;
      align-items: center;
      flex-direction: column;

      &__imgContainer {
        background: $bg;
        line-height: 0;

        .notavail {
          font-style: italic;
          color: $greyDark;
        }
      }

      &__text {        
        transition: transform 0.2s linear, opacity 0.2s linear;
        opacity: 0;
        padding: 2px 8px;
        border-radius: 4px;
        background-color: $mainDark;
        // opacity: 0.6;

        font-size: 8pt;
        font-weight: 400;
        padding: 5px;
        color: $white;
      }

      .text-title {
        transform: translateY(-25px);
        position: absolute;
        margin: 13px 9px;
        top: 0; 
        left: 0;
      }

      .text-pages {
        transform: translateY(25px);
        position: absolute;
        margin: 7px 9px;
        bottom: 0;
        left: 0;
      }

      &:hover {
        background: $bgDark-highlight;
        
        .item-btn__imgContainer {
          opacity: 0.5;
        }

        .item-btn__text {
          opacity: 0.6;
          transform: translateY(0);
        }
      }
    }
  }
}
