@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.editor {
  @include fullsize;
  display: flex;
  align-items: stretch;

  &__content {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;

    .go4153082118>div:last-child>span {

      span.bp4-popover2-target span.bp4-button-text {
        width: 21px;
      }

      button {
        text-align: center;
        padding: 5px;
      }
     }

    .workspace {
      position: relative;
      width: 100%;
      height: 100%;
      // height: calc(100% - 100px);
      overflow: auto;

      .float-buttons {
        position: relative;
        
        .go361650188 {
        left: auto;
        right: 0;
        margin: 0px 50px 15px 0px;
        box-shadow: none;

          .bp4-navbar {
            background-color: $bgDark;
            box-shadow: none;

            .bp4-icon {
              color: $greyDark2;
            }

            .bp4-button-text {
              color: $white !important;
            }
          }
        }

        .pages-toggle {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 10;
          padding: 7px 12px;
          border: none;
          border-radius: 4px;
          margin: 20px 15px;

          background-color: $bgDark;
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }

          &__text {
            color: $white;
            font-size: 12pt;

            &--numbers {
              color: $greyDark2;
            }
          }
        }
      }

      .pagesidebar-container {
        position: absolute;
        right: 0;
        top: 0;
        width: 280px;
        height: 100%;
        background-color: $white;

        box-shadow: -6px 8px 23px -5px rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: -6px 8px 23px -5px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: -6px 8px 23px -5px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
