@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.elements-content {
  @include fullsize;

  .loading {
    @include fullsize;
    @include flex-center;
    .bars {
      height: 15%;
      width: 15%;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    // padding-bottom: 0.5rem;

    .item-btn {
      @include flex-center;
      background: #17252f;
      width: 100%;
      height: 8rem;
      padding: 10px;
      margin-bottom: 10px;
      border: none;
      color: $primaryLight;

      &:hover,
      &:active,
      &:focus {
        background: #12232f;
      }

      .item-btn-text {
        font-size: 18pt;
        font-weight: 500;
        color: $primaryLight;
      }
    }
  }
}
