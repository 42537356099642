@import '../../../../assets/styles/custom/variables.scss';
@import '../../../../assets/styles/custom/mixins.scss';


.user-images { 
  width: 100%;
  color: $primaryLight;

  .section {
    margin-bottom: 1.5rem;

    &__header {
      @include button-reset;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &--text{
        font-weight: 500;
        font-size: 10pt;
        letter-spacing: 0.88px;
        color: $primaryLight;
      }
    }
  }

  .loader {
    width: 20%;
    margin: auto;
  }

  .breadcrumbWrapper {
    margin-bottom: 0.5em;
    background-color: $bgDarker;
  }

  .breadcrumb {
    color: $primaryLight;
    min-height: 30px;
    line-height: 1;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .breadcrumb-custom-item {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    line-height: 0;

    a {
      color: $primaryLight;

      &--text {
        font-size: 9pt;
        margin: 0;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &::before {
      float: unset;
      padding-right: 0;
      font-size: 18px;
      font-weight: bold;
      margin: 0 5px;
    }
  }

  .dl-button{
    width: 100%;

    &--text {
      font-size: 10pt;
      font-weight: 500;
    }
  }

  .folders {
    display: flex;
    flex-direction: column;
    margin-top: 0.8em;

    &__item {
      @include button-reset;
      padding: 8px 13px;
      margin: 2.5px 0;
      border-radius: 5px;
      background-color: $bgDarker;

      display: flex;
      flex-direction: row;
      align-items: center;

      &--icon {
        margin-right: 1rem;
      }
      &--text {
        color: $primaryLight;
        font-size: 10pt;
      }

      &:hover {
        background-color: $bgDark-highlight;
      }
    }
  }

  .images-section {
    margin-top: 1em;

    .imagecolumns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      padding-bottom: 0.5rem;
      &__column {
        display: flex;
        flex-direction: column;
        flex: 1;
  
        .img-item {
          &--image {
            width: 100%;
            margin-bottom: 10px;
            border-radius: 3px;
          }
  
          &--delete {
            visibility: hidden;
            position: absolute;
            top: 0;
            right: 0;
            margin: 10px 10px;
            padding: 5px 5px;
            border: none;
            border-radius: 8px;
            background-color: $bgDark;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }

          &:hover {
            cursor: pointer;
            .img-item--image {
              opacity: 0.5;
            }

            .img-item--delete {
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .hiddeninput {
    display: none;
  }

  .dialog-button {
    color: $primaryLight !important
  }
}