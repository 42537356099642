@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  height: 100%;
}

body {
  font-family: 'Larsseit', sans-serif !important;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: $mainDark;
}

p,
div,
h1,
h2,
h3,
h4,
h5,
ul,
a,
li,
ul,
ol,
span,
section,
label,
main,
aside,
footer,
header,
form,
input,
textarea,
button {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;

  &:before,
  &:after {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  cursor: pointer;
}

svg {
  width: 100% !important;
  height: 100% !important;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 16px;
}

.h3 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  color: $mainDark;
}

.auth-from {
  .input {
    margin-bottom: 16px;
  }

  .button {
    margin-top: 16px;
    width: 100%;
  }
}

.page-content {
  padding: 24px 20px;
}

.export-wrap {
  display: flex;
  align-items: flex-end;
  padding-top: 20px;

  &__actions {
    padding-left: 20px;
  }
}

a {
  color: $primary;
}

.audit .table td {
  font-size: 14px;
}

.search-bar {
  display: flex;
  align-items: center;

  .input,
  .select-menu {
    margin-left: 15px;
  }

  .select-menu {
    width: 150px;

    &__control {
      height: 40px;
    }
  }
}

.empty-button {
  padding: 0;
  background-color: transparent;
  border: none;
}

h5.sidebar-header {
  color: $white;
  font-weight: 400;
  font-size: 12pt;
  letter-spacing: 1.8px;
  margin-bottom: 1.5rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(181, 181, 189, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b4b4bc;
}

@media (max-width: $mobileBreakpoint) {
  body {
    font-size: 14px;
    line-height: 20px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
  }
}

.Toastify {
  &__close-button > svg {
    height: 16px !important;
    width: 14px !important;
  }
}