@import '../../assets/styles/custom/variables.scss';
@import '../../assets/styles/custom/mixins.scss';

.headerTitleIcon {
  height: 25px;
  margin-right: 15px
}

.propertySearchHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  padding: 15px 23px;
  box-shadow: 0px 0px 1px 1px #0000003B;
  -webkit-box-shadow: 0px 0px 1px 1px #0000003B;
  -moz-box-shadow: 0px 0px 1px 1px #0000003B;

  .headerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 0;      


    .headerTitleText {
      color: $mainDark2;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .headerCloseIcon {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    height: 23px;
  }
}

.propertySearchSidebarContainer {
  display: flex;
  width: 25%;
  height: 100%;
  box-shadow: 3px 0px 13px #00000012;
  -webkit-box-shadow: 3px 0px 13px #00000012;
  -moz-box-shadow: 3px 0px 13px #00000012;
}

.propertySearchContent {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: $bg3;
}

.propertySearchMainContent {
  flex: 1;
  max-width: 75%;
  padding: 12px 24px;
  overflow-y: scroll;
  // display: grid;
  // grid-template-columns: repeat(auto-fill,minmax(280px, 1fr));
}

.loader {
  @include fullsize;
  @include flex-center;
  .bars {
    height: 15%;
    width: 15%;
  }
}

.propertySearchPropertyList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.propertyItemContainer {
  width: 275px;
  min-height: 245px;

  border: none;
  background-color: $white;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 8px 14px;
  margin: 0px 16px 16px 0px;
  color: $greyDark;
  font-size: 12px;

  box-shadow: 0px 3px 13px #00000012;
  -webkit-box-shadow: 0px 0px 13px #00000012;
  -moz-box-shadow: 0px 0px 13px #00000012;

  .propertyItemRow {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  p {
    margin: 0;
    padding-right: 8px;
  }

  .propertySpecs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    
    .propertySpecsRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .propertyPriceRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }

    .saleDateBg {
      text-align: right;
      padding: 2px 9px;
      background-color: $greyLight4;
      border-radius: 10px;
      font-size: 10px;
    }

    .priceHiddenIcon {
      height: 12px;
      margin-right: 6px;
    }
  }
  
  .propertyItemThumbnailContainer {
    margin-bottom: 6px;
    position: relative;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }

    &:hover { 
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $bgDark;
        opacity: 0.5;
      }
    }
  }
}

.propertyItemSelected {
  border: 2px solid $primary;
}
