@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.button {
  // margin: 0 0.25rem;
  // border: none;
  background-color: $primary;
  border-radius: 0.25em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }

  &--white {
    background-color: $white;
    color: $bgDark;
  }
  
  &--secondary {
    background-color: $greyLight4;
    border-color: $greyLight4;
    color: $mainDark2;
  }

  &--empty {
    background-color: transparent;
    border-color: transparent;
    color: $bgDark;

    &:focus {
      border-color: rgba(215, 215, 215, 0.6);
      outline: rgba(215, 215, 215, 0.6) auto 2px !important;
      box-shadow:  0 0 0.25rem rgba(215, 215, 215, 0.6)!important;
    }

    &:hover,
    &:active,
    &:focus {
        border-color: transparent;
        background-color: $bg;
        color: $bgDark;
    }
  }

  &:disabled {
    opacity: 50%;
    cursor: default;
  }


  &--text {
    line-height: 0;
    margin: 0;
    padding: 0;
  }

  .icon-container {
    height: 20px;
    margin-right: 5px;
  }
}

.rounded {
  border-radius: 3px;
}

@media (max-width: $mobileBreakpoint) {
  .button {
    height: 40px;
    font-size: 14px;
    line-height: 38px;
  }
}
