@import '../../assets/styles/custom/variables.scss';
@import '../../assets/styles/custom/mixins.scss';

.gridToolbar {
    box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 1px 1px rgb(17 20 24 / 20%);
    padding: 0.25rem 15px 0.25rem 15px;
    min-height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $white;

    &__buttoncol {
        @include flex-center;
        flex-direction: row;
    }

    &__fields {
        @include flex-center;
        flex-direction: row;

        input {
            padding: 0.2rem;
            width: 50px;
            text-align: center;
            border: 0.5px solid $greyLight;
        }

        label {
            color: $mainDark
        }

        * {
            margin-right: 15px;
        }
    }
}