@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.images-content {
  .images-tab-content {
    height: 100%;
  }

  .folders {
    &__item {
      transition: background-color .30s ease-in-out;
    }
  }
}
