@import '../../../assets/styles/custom/mixins.scss';

.landing {
  @include flex-center;
  @include fullsize;

  &__text {
    text-align: center;
    width: 60%;
  }
}
