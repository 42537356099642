@import '../../../assets/styles/custom/variables.scss';
@import '../../../assets/styles/custom/mixins.scss';

.mergefields-content {
  @include fullsize;
  color: $primaryLight;

  .sub-heading {
    color: $primaryLight;
  }
  .select-container {
    width: 100%;
    margin-bottom: 1rem;
  }
  .mergefield-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .field-btn {
      margin-bottom: 9px;
      border: none;
      background-color: transparent;
      text-align: left;
      color: $primaryLight;

      &--text {
        font-size: 11pt;
        font-weight: 300;

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
  }
}

.custom__dropdown {
  .btn-primary.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #17252f;
    color: #acc9d8;
    border-color: #324554;

    &:hover, &:active, &:focus {
      background: #324554;
      color: #acc9d8;
    }
  }

  .dropdown-menu.show {
    width: 100%;
  }
}
